<template>
<!-- 我的待维修(维修员) -->
  <div class="container">
    <van-tabs v-model="active" color="#07c160" @change="changeActive">
      <van-tab title="待接单">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getList"
        >
          <div class="diyCell" v-for="item in list" :key="item.id" @click="goDetail(item.id)">
            <div class="top">
              <div class="title van-ellipsis">{{ item.repairTypeName }}</div>
              <van-tag v-if="item.status == '0'" size="medium" color="#f4f4f5" text-color="#909399">待处理</van-tag>
              <van-tag v-else-if="item.status == '1'" size="medium" color="#f0f9eb" text-color="#67c23a">处理中</van-tag>
              <van-tag v-else-if="item.status == '2'" size="medium" color="#fdf6ec" text-color="#e6a28b">处理完成</van-tag>
              <van-tag v-else-if="item.status == '3'" size="medium" color="#dbedf9" text-color="#0080cb">已解决</van-tag>
              <van-tag v-else-if="item.status == '4'" size="medium" color="#f4f4f5" text-color="#909399">未解决</van-tag>
            </div>
            <p class="van-multi-ellipsis--l3">{{ item.remark }}</p>
            <div class="time">
              <span>{{ item.createTime }}</span>
              <van-icon name="arrow" />
            </div>
          </div>
        </van-list>
      </van-tab>

      <van-tab title="已接单">
        <van-list
          v-model="loading2"
          :finished="finished2"
          finished-text="没有更多了"
          @load="getMyList"
        >
          <div class="diyCell" v-for="item in myList" :key="item.id" @click="goDetail(item.id)">
            <div class="top">
              <div class="title van-ellipsis">{{ item.repairTypeName }}</div>
              <van-tag v-if="item.status == '0'" size="medium" color="#f4f4f5" text-color="#909399">待处理</van-tag>
              <van-tag v-else-if="item.status == '1'" size="medium" color="#f0f9eb" text-color="#67c23a">处理中</van-tag>
              <van-tag v-else-if="item.status == '2'" size="medium" color="#fdf6ec" text-color="#e6a28b">处理完成</van-tag>
              <van-tag v-else-if="item.status == '3'" size="medium" color="#dbedf9" text-color="#0080cb">已解决</van-tag>
              <van-tag v-else-if="item.status == '4'" size="medium" color="#f4f4f5" text-color="#909399">未解决</van-tag>
            </div>
            <p class="van-multi-ellipsis--l3">{{ item.remark }}</p>
            <div class="time">
              <span>{{ item.createTime }}</span>
              <van-icon name="arrow" />
            </div>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
    
  </div>
</template>
<script>
import { workList, getMyWorkList } from '@/api/repairApi'
export default {
  data() {
    return {
      active: '待接单',
      list: [],
      myList: [],
      loading: false,
      loading2: false,
      finished: false,
      finished2: false,
      page: 1,
      page2: 1,
      currentList: -1,
      currentList2: -1,
      
    }
  },
  created() {
    // this.getList()
  },
  mounted() {},
  methods: {
    changeActive() {
    },
    goDetail(id) {
      this.$router.push({
        path: `/myWorkListDetail?id=${id}&isWorker=1`
      }) 
    },
    getList() {
      if (this.currentList !== 0) {
        
        workList({
          repairUser: this.$store.getters.userInfo.id,
          current: this.page,
          size: 10,
        }).then(res => {
          if (res.code === 200) {
            this.loading = false
            this.currentList = res.data.records ? res.data.records.length : 0
            this.list = this.list.concat(res.data.records)
            this.page++ 
          }
        })
      } else {
        this.finished = true; 
      }
      
    },
    getMyList() {
      if (this.currentList2 !== 0) {
        
        getMyWorkList({
          repairUser: this.$store.getters.userInfo.id,
          current: this.page2,
          size: 10,
        }).then(res => {
          if (res.code === 200) {
            this.loading2 = false
            this.currentList2 = res.data.records ? res.data.records.length : 0
            this.myList = this.myList.concat(res.data.records)
            this.page2++ 
          }
        })
      } else {
        this.finished2 = true; 
      }
      
    },
  },
}
</script>
<style scoped lang="scss">
.container {
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
}

.van-cell__value {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.ml {
  margin-left: 5px;
}
.diyCell {
  width: 94%;
  margin-left: 3%;
  box-sizing: border-box;
  padding: 10px;
  padding-bottom: 0;
  border-radius: 5px;
  margin-top: 10px;
  background-color: #fff;
  box-shadow: 0 5px 5px #efefef;
  display: flex;
  flex-direction: column;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
    .title {
      flex: 1;
      font-weight: 600;
      font-size: 14px;
    }
  }
  p {
    height: 48px;
    margin-bottom: 6px;
    font-size: 12px;
    color: #5f5f5f;
  }
  .time {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #f3f3f3;
    color: #b9b9b9;
    font-size: 12px;
  }
}
</style>
